import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import {
  createSmtpGateway, createSmsGateway, editSmtpGateway, editSmsGateway,
} from '../../../apis/sendingProfiles.ts';
import EmailHeadersComponent from './EmailHeadersComponent/EmailHeadersComponent';
import TestMailModalComponent from '../../Campaigns/ManageCampaignFormComponent/TestMailModalComponent/TestMailModalComponent';
import { useToast } from '../../../context/ToastContext';

const interfaceTypeOptions = [
  {
    name: 'SMTP',
  },
  {
    name: 'SMS Gateway',
  },
];

function ManageSendingProfilesFormComponent({ sendingProfile, mode, setVisible }) {
  const [visibleTestMail, setVisibleTestMail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showToken, setShowToken] = useState(false);
  const [selectedInterfaceType, setSelectedInterfaceType] = useState();
  const [headers, setHeaders] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Sending profile name must be at least 2 characters').max(255, 'Sending profile name must be at most 255 characters').required('Sending profile name is required'),
    interface_type: yup.object().shape({
      name: yup.string().test('name-exists', 'Name field is required', (value) => !!value).required('Name field is required'),
    }),
    from_address: yup.string().when('interface_type.name', {
      is: 'SMTP',
      then: () => yup.string().email('SMTP from address must be a valid email').required('SMTP from address is required'),
      otherwise: () => yup.string(),
    }),
    host: yup.string().when('interface_type.name', {
      is: 'SMTP',
      then: () => yup.string().required('SMTP host is required'),
      otherwise: () => yup.string(),
    }),
    smsHost: yup.string().when('interface_type.name', {
      is: 'SMS Gateway',
      then: () => yup.string()
        .required('SMS Gateway host is required')
        .matches(/[^/]$/, 'SMS Gateway host cannot end with a slash (/)'),
      otherwise: () => yup.string(),
    }),
    token: yup.string().when('interface_type.name', {
      is: 'SMS Gateway',
      then: () => yup.string().required('Token is required'),
      otherwise: () => yup.string(),
    }),
    endpoint: yup.string().when('interface_type.name', {
      is: 'SMS Gateway',
      then: () => yup.string()
        .required('Endpoint URL is required')
        .matches(/^\/.*\/$/, 'SMS Gateway endpoint must start and end with a slash (/)'),
      otherwise: () => yup.string(),
    }),
    chunksize: yup.number().when('interface_type.name', {
      is: 'SMS Gateway',
      then: () => yup.number().min(1, 'Minimun chunk size of 1').required('Chunk size is required'),
      otherwise: () => yup.number(),
    }),
    smishingChunkInterval: yup.number().when('interface_type.name', {
      is: 'SMS Gateway',
      then: () => yup.number().min(0, 'Interval time can not be negative'),
      otherwise: () => yup.number(),
    }),
  });

  const defaultValues = {
    name: '',
    shared: false,
    interface_type: '',
    from_address: '',
    host: '',
    username: '',
    password: '',
    ignore_cert_errors: false,
    smsHost: '',
    token: '',
    endpoint: '',
    chunksize: 100,
    smishingChunkInterval: 0,
    type: 'Acumbamail',
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    getValues,
    setValue,
    reset,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (sendingProfile) {
      switch (sendingProfile.interface_type) {
        case 'SMTP':
          setHeaders(sendingProfile.headers);
          setSelectedInterfaceType(sendingProfile.interface_type);
          reset({
            name: mode === 'edit' ? sendingProfile.name : `Copy of ${sendingProfile.name}`,
            shared: sendingProfile.shared,
            interface_type: { name: sendingProfile.interface_type },
            from_address: sendingProfile.from_address,
            host: sendingProfile.host,
            username: sendingProfile.username,
            password: sendingProfile.password,
            ignore_cert_errors: sendingProfile.ignore_cert_errors,
          });
          break;
        case 'SMS Gateway':
          setSelectedInterfaceType(sendingProfile.interface_type);
          reset({
            name: mode === 'edit' ? sendingProfile.name : `Copy of ${sendingProfile.name}`,
            shared: sendingProfile.shared,
            interface_type: { name: sendingProfile.interface_type },
            endpoint: sendingProfile.endpoint,
            smsHost: sendingProfile.host,
            token: sendingProfile.auth_token,
            chunksize: sendingProfile.chunk_size,
            smishingChunkInterval: sendingProfile.smishing_chunk_interval || 0,
            type: sendingProfile.type,
          });
          break;
        default:
      }
    }
  }, [sendingProfile]);

  async function onSubmit() {
    setIsSubmitting(true);
    const data = { ...getValues(), headers };
    let response = {};
    if (selectedInterfaceType === 'SMTP') {
      const sanitizedSendData = Object.fromEntries(
        // eslint-disable-next-line no-unused-vars
        Object.entries(data).filter(([_, v]) => v !== undefined),
      );
      if (mode === 'edit') {
        response = await editSmtpGateway({
          ...sanitizedSendData, id: sendingProfile.id, interface_type: selectedInterfaceType,
        });
      } else {
        response = await createSmtpGateway({
          ...sanitizedSendData, interface_type: selectedInterfaceType,
        });
      }
    }
    if (selectedInterfaceType === 'SMS Gateway') {
      const {
        name,
        shared,
        smsHost,
        chunksize,
        smishingChunkInterval,
        token,
        endpoint,
        type,
        // eslint-disable-next-line no-unused-vars
        ...rubishData
      } = getValues();
      if (mode === 'edit') {
        response = await editSmsGateway({
          name,
          shared,
          host: smsHost,
          chunk_size: chunksize,
          smishing_chunk_interval: smishingChunkInterval,
          interface_type: selectedInterfaceType,
          auth_token: token,
          endpoint,
          id: sendingProfile.id,
          type,
        });
      } else {
        response = await createSmsGateway({
          name,
          shared,
          host: smsHost,
          chunk_size: chunksize,
          smishing_chunk_interval: smishingChunkInterval,
          interface_type: selectedInterfaceType,
          auth_token: token,
          endpoint,
          type,
        });
      }
    }
    if (response.response?.data.success === false || response.isAxiosError) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.response.data.message || `An unexpected error occurred ${mode === 'edit' ? 'editing' : 'creating'} the sending profile`, life: 3000,
      });
    } else {
      setVisible(false);
      toast.current.show({
        severity: 'success', summary: 'Success', detail: mode === 'edit' ? 'Sending profile edited' : 'Sending profile created', life: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsSubmitting(false);
  }

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    <>
      <TestMailModalComponent
        visible={visibleTestMail}
        setVisible={setVisibleTestMail}
        smtp={{ ...getValues(), headers }}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <div>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Name*
                </label>
                <InputText
                  id={field.name}
                  placeholder="Profile name"
                  value={field.value}
                  className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                  onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                  aria-describedby={`${field.name}-info`}
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          <div className="mb-5">
            <Controller
              name="shared"
              control={control}
              render={({ field }) => (
                <label htmlFor={field.name} className="block">
                  <Checkbox
                    id={field.name}
                    inputId={field.name}
                    checked={field.value}
                    inputRef={field.ref}
                    className="mr-2"
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                  />
                  Share sending profile
                  <Tooltip target=".custom-target-icon" className="w-3" />
                  <i
                    className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                    data-pr-tooltip="If you share the sending profile, it will be accessible for use by other users associated with your account."
                    data-pr-position="right"
                    data-pr-at="right+5 top"
                    data-pr-my="left center-2"
                    style={{ cursor: 'pointer' }}
                  />
                </label>
              )}
            />
          </div>
          <Controller
            name="interface_type"
            control={control}
            render={({ field }) => (
              <div className="mb-2">
                <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                  Interface type*
                </label>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => {
                    setSelectedInterfaceType(e.value.name);
                    setValue(field.name, { name: e.value.name });
                    trigger(field.name);
                    trigger('from_address');
                    trigger('host');
                  }}
                  options={interfaceTypeOptions}
                  optionLabel="name"
                  placeholder="Select an interface type"
                  className="w-full md:w-30rem"
                  aria-describedby={`${field.name}-info`}
                  disabled={mode === 'edit'}
                  required
                />
                <div className="mb-1">
                  {getFormErrorMessage(field.name)}
                </div>
              </div>
            )}
          />
          {selectedInterfaceType === 'SMTP' && (
            <>
              <Controller
                name="from_address"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      SMTP from*
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="test@example.com"
                      value={field.value}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger('from_address'); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="host"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Host*
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="smtp.example.com:25"
                      value={field.value}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger('host'); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="username"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Username*
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="Username"
                      value={field.value}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger('username'); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Password*
                    </label>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={field.value}
                        className="w-full md:w-30rem"
                        onChange={(e) => { field.onChange(e.target.value); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowPassword(!showPassword)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle Password Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowPassword(!showPassword)}
                      />
                    </span>
                    <div>
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="ignore_cert_errors"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name}>
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => field.onChange(e.checked)}
                    />
                    Ignore Certificate Errors
                    <Tooltip target=".custom-target-icon" className="w-3" />
                    <i
                      className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                      data-pr-tooltip="Ignore common certificate errors such as self-signed certs (exposes you to MiTM attacks - use carefully!)"
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                )}
              />
              <EmailHeadersComponent setHeaders={setHeaders} headers={headers} />
              <div className="mt-4">
                <Button type="button" onClick={() => setVisibleTestMail(true)}>Send test mail</Button>
              </div>
            </>
          )}
          {selectedInterfaceType === 'SMS Gateway' && (
            <>
              <Controller
                name="smsHost"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Host*
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="https://acumbamail.com"
                      value={field.value}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="token"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Token*
                    </label>
                    <span className="p-input-icon-right">
                      <InputText
                        id={field.name}
                        placeholder="X8e2Lg3rP7sF9tN0"
                        type={showToken ? 'text' : 'password'}
                        value={field.value}
                        className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                        onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                        aria-describedby={`${field.name}-info`}
                      />
                      <i
                        className={showToken ? 'pi pi-eye-slash' : 'pi pi-eye'}
                        onClick={() => setShowToken(!showToken)}
                        tabIndex="0"
                        role="button"
                        aria-label="Toggle Token Visibility"
                        onKeyDown={(e) => e.key === 'Enter' && setShowToken(!showToken)}
                      />
                    </span>
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="endpoint"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Endpoint URL*
                    </label>
                    <InputText
                      id={field.name}
                      placeholder="/api/1/sendSMS/"
                      value={field.value}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="chunksize"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Chunk size*
                    </label>
                    <InputNumber
                      id={field.name}
                      value={field.value}
                      placeholder="120"
                      min={1}
                      onValueChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      onChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      showButtons
                      buttonLayout="horizontal"
                      step={1}
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      aria-describedby={`${field.name}-info`}
                      className={`w-full md:w-10rem ${fieldState.error ? 'p-invalid' : ''}`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="smishingChunkInterval"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Sending chunk interval (in seconds)
                    </label>
                    <InputNumber
                      id={field.name}
                      value={field.value}
                      placeholder="0"
                      min={0}
                      onValueChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      onChange={
                        (e) => { field.onChange(e.value); trigger(field.name); }
                      }
                      showButtons
                      buttonLayout="horizontal"
                      step={1}
                      incrementButtonIcon="pi pi-plus"
                      decrementButtonIcon="pi pi-minus"
                      aria-describedby={`${field.name}-info`}
                      className={`w-full md:w-10rem ${fieldState.error ? 'p-invalid' : ''}`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="mb-2">
                    <label htmlFor={field.name} className="block text-500 font-medium mb-2">
                      Type
                    </label>
                    <InputText
                      id={field.name}
                      value={field.value}
                      disabled
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mb-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
            </>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem' }}>
            <Button
              label={isSubmitting ? 'Saving...' : 'Save'}
              icon={isSubmitting ? 'pi pi-spin pi-spinner' : 'pi pi-save'}
              disabled={!isValid || isSubmitting}
              type="button"
              onClick={() => onSubmit()}
              autoFocus
            />
          </div>
        </div>
      </form>
    </>
  );
}

ManageSendingProfilesFormComponent.propTypes = {
  sendingProfile: PropTypes.object,
  mode: PropTypes.string,
  setVisible: PropTypes.func.isRequired,
};

ManageSendingProfilesFormComponent.defaultProps = {
  sendingProfile: {},
  mode: null,
};

export default ManageSendingProfilesFormComponent;
