import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TitleComponent from '../components/TitleComponent/TitleComponent';
import SideMenuComponent from '../components/SideMenuComponent/SideMenuComponent';
import FooterComponent from '../components/FooterComponent/FooterComponent';
import './page.scss';
import getUserInfoState from '../redux/reducers/selectors/userInfo';
import { getLoggedUser } from '../apis/authentication.ts';

export default function withContent(WrappedComponent) {
  // eslint-disable-next-line react/prop-types
  return function Page({ title, ...props }) {
    useEffect(() => {
      async function fetchData() {
        const res = await getLoggedUser();
        if (!res.success) {
          window.location.href = '/login';
        }
      }
      fetchData();
    }, []);
    const logged = useSelector(getUserInfoState);
    if (logged.name === '') {
      window.location.href = '/login';
    } else {
      return (
        <div className="global-container">
          <div className="page-wrapper">
            <SideMenuComponent />
            <div style={{ flex: 'auto' }}>
              <TitleComponent title={title} />
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <WrappedComponent {...props} />
            </div>
          </div>
          <div className="page-footer page-container">
            <FooterComponent />
          </div>
        </div>
      );
    }
  };
}
