/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileUpload } from 'primereact/fileupload';
// import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Fieldset } from 'primereact/fieldset';
import { TabView, TabPanel } from 'primereact/tabview';
import './ManageGroupFormComponent.scss';
import Papa from 'papaparse';
import { Checkbox } from 'primereact/checkbox';
import { useToast } from '../../../context/ToastContext';
import ImportGroupModalConfirmation from './ImportGroupModalConfirmation';
import { chooseOptions, uploadOptions, cancelOptions } from '../../utils';

function downloadCSVTemplate() {
  const csvScope = [{
    'First Name': 'Example',
    'Last Name': 'User',
    Email: 'foobar@example.com',
    Position: 'Systems Administrator',
    'Phone Number': '+34666666666',
  }];
  const filename = 'group_template.csv';
  const csvString = Papa.unparse(csvScope, {});
  const csvData = new Blob([csvString], {
    type: 'text/csv;charset=utf-8;',
  });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(csvData, filename);
  } else {
    const csvURL = window.URL.createObjectURL(csvData);
    const dlLink = document.createElement('a');
    dlLink.href = csvURL;
    dlLink.setAttribute('download', filename);
    document.body.appendChild(dlLink);
    dlLink.click();
    document.body.removeChild(dlLink);
  }
}

const phoneRegex = /^[0-9+\-()\s]*$/;

function ManageGroupFormComponent({ setGroup, group, setGroupUsers }) {
  const [showImportGroupModal, setShowImportGroupModal] = useState(false);
  const [formImportGroupData, setFormImportGroupData] = useState(null);
  const toast = useToast();
  const schema = yup.object().shape({
    groupname: yup.string().min(2, 'Group name must be at least 2 characters').max(255, 'Group name must be at most 255 characters').required('Group name is required'),
    first_name: yup.string().max(255, 'First name must be at most 255 characters').nullable(),
    last_name: yup.string().max(255, 'Last name must be at most 255 characters').nullable(),
    email: yup.string().email('Email must be a valid email').required('Email is required'),
    phone_number: yup.string()
      .matches(phoneRegex, 'Phone number can only contain numbers, spaces, dashes, parentheses, and plus signs')
      .nullable(),
  });

  const defaultValues = {
    groupname: '',
    shared: false,
    first_name: null,
    last_name: null,
    email: '',
    // annotation: '',
    position: '',
    phone_number: '',
  };

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    if (group) {
      setValue('groupname', group.name);
      setValue('shared', group.shared);
    }
  }, [group]);

  const onSubmit = (data) => {
    const { groupname, ...sendData } = data;
    const sanitizedSendData = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(sendData).filter(([_, v]) => v !== undefined),
    );
    setGroupUsers((prevGroupUsers) => {
      const index = prevGroupUsers.findIndex((user) => user.email === sanitizedSendData.email);
      if (index !== -1) {
        // If a user with the same email exists, replace it
        const updatedGroupUsers = [...prevGroupUsers];
        updatedGroupUsers[index] = sanitizedSendData;
        return updatedGroupUsers;
      }
      // If no user with the same email exists, add the new user
      return [...prevGroupUsers, sanitizedSendData];
    });
  };

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    const { files } = e;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const {
      className, chooseButton, uploadButton, cancelButton,
    } = options;
    // const value = totalSize / 100000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <Button
          icon="pi pi-file-excel"
          tooltip="Download a CSV template for importing users"
          tooltipOptions={{ position: 'bottom' }}
          onClick={downloadCSVTemplate}
        >
          Download CSV template
        </Button>
        <div className="flex align-items-center gap-3 ml-auto">
          <span>
            {formatedValue}
            {' '}
            {/* / 10 MB */}
          </span>
          {/* <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }} /> */}
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <i className="pi pi-file" style={{ fontSize: '2rem', marginRight: '1rem' }} />
        <span className="flex flex-column text-left ml-3">
          {file.name}
          <small>{new Date().toLocaleDateString()}</small>
        </span>
      </div>
      {/* eslint-disable-next-line react/prop-types */}
      <Tag value={props.formatSize} severity="warning" className="px-3 py-2 ml-auto" style={{ width: 'auto', flexShrink: 0 }} />
      {/* eslint-disable-next-line react/prop-types */}
      <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
    </div>
  );

  const emptyTemplate = () => (
    <div className="flex align-items-center flex-column">
      <i
        className="pi pi-image mt-3 p-5"
        style={{
          fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)',
        }}
      />
      <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
        Drag and Drop Image Here
      </span>
    </div>
  );

  const uploadConfirmation = ({ files }) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('file', file);
    setFormImportGroupData(formData);
    setShowImportGroupModal(true);
  };

  const onConfirmUpload = () => {
    uploadHandler(formImportGroupData);
    setShowImportGroupModal(false);
  };

  const onCancelUpload = () => {
    onTemplateClear();
    setShowImportGroupModal(false);
  };

  const uploadHandler = async (formData) => {
    try {
      const response = await fetch('/api/import/group', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        if (result.length) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Users added correctly' });
          setGroupUsers(result);
          setTotalSize(0);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'No users were detected on the file' });
        }
      } else {
        const result = await response.json();
        toast.current.show({ severity: 'error', summary: 'Error', detail: `There was an error uploading the users: ${result.message}` });
      }
    } catch (error) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'There was an error uploading the users' });
    }
  };

  return (
    <>
      <ImportGroupModalConfirmation
        visible={showImportGroupModal}
        onHide={onCancelUpload}
        onConfirm={onConfirmUpload}
        message="This action will replace ALL the current users of the group for the imported ones. Do you want to proceed with the file upload?"
        header="Upload Confirmation"
      />
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2 text-left ml-3">
        <Fieldset legend="General" toggleable style={{ marginLeft: '-1rem' }}>
          <div className="flex flex-row gap-2 mt-2">
            <div className="flex-auto">
              <Controller
                name="groupname"
                control={control}
                render={({ field, fieldState }) => (
                  <div>
                    <InputText
                      id={field.name}
                      placeholder="Group name"
                      value={field.value || ''}
                      className={`w-full md:w-30rem ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        trigger(field.name);
                        setGroup({ ...group, name: e.target.value });
                      }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <div className="mt-1">
                      {getFormErrorMessage(field.name)}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="shared"
                control={control}
                render={({ field }) => (
                  <label htmlFor={field.name} className="block mb-2">
                    <Checkbox
                      id={field.name}
                      inputId={field.name}
                      checked={field.value}
                      inputRef={field.ref}
                      className="mr-2"
                      onChange={(e) => {
                        field.onChange(e.checked); setGroup({ ...group, shared: e.checked });
                      }}
                    />
                    Share group
                    <Tooltip target=".custom-target-icon" className="w-3" />
                    <i
                      className="custom-target-icon pi pi-question p-text-secondary p-overlay-badge"
                      data-pr-tooltip="If you share the group, it will be accessible for use by other users associated with your account."
                      data-pr-position="right"
                      data-pr-at="right+5 top"
                      data-pr-my="left center-2"
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                )}
              />
            </div>
            {/* <div className="flex-auto">
            <Controller
              name="annotation"
              control={control}
              render={({ field }) => (
                <>
                  <InputText
                    id={field.name}
                    placeholder="Annotation"
                    value={field.value}
                    onChange={(e) => { setValue('annotation', e.target.value); }}
                    aria-describedby={`${field.name}-info`}
                    style={{ width: '100%' }}
                  />
                  <div />
                </>
              )}
            />
          </div> */}
          </div>
        </Fieldset>
        <Fieldset legend="Add users" toggleable className="mb-2" style={{ marginLeft: '-1rem' }}>
          <TabView>
            <TabPanel header="Import from file" leftIcon="pi pi-upload mr-2">
              <div className="mb-5">
                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
                <Tooltip target=".download-btn" content="Download a CSV template for importing users" position="right" />
                <FileUpload
                  ref={fileUploadRef}
                  name="demo[]"
                  customUpload
                  // multiple
                  accept=".csv"
                  maxFileSize={10000000}
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  onError={onTemplateClear}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  itemTemplate={itemTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                  uploadOptions={uploadOptions}
                  cancelOptions={cancelOptions}
                  uploadHandler={uploadConfirmation}
                />
              </div>
            </TabPanel>
            <TabPanel header="Manual addition" leftIcon="pi pi-pencil mr-2">
              <div className="flex flex-column md:flex-row gap-2 mt-2">
                <div className="flex-auto">
                  <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <InputText
                          id={field.name}
                          style={{ width: '100%' }}
                          placeholder="Email*"
                          value={field.value || ''}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                        <div>
                          {getFormErrorMessage(field.name)}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="flex-auto ml-3">
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <Tooltip target=".custom-target-icon" className="w-3" />
                        <i
                          className="custom-target-icon pi pi-exclamation-circle p-text-secondary p-overlay-badge mr-2"
                          data-pr-tooltip="Phone numbers must have the country prefix (i.e. 0034 or +34)"
                          data-pr-position="top"
                          data-pr-at="center top-5"
                          data-pr-my="center bottom"
                          style={{ cursor: 'pointer' }}
                        />
                        <InputText
                          id={field.name}
                          placeholder="Phone number"
                          style={{ width: '95%' }}
                          value={field.value || ''}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                        <div>
                          {getFormErrorMessage(field.name)}
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="flex flex-column md:flex-row gap-2 mb-4">
                <div className="flex-auto">
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <InputText
                          id={field.name}
                          style={{ width: '100%' }}
                          placeholder="First name"
                          value={field.value || ''}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                        <div>
                          {getFormErrorMessage(field.name)}
                        </div>
                      </div>
                    )}
                  />
                </div>

                <div className="flex-auto">
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <InputText
                          id={field.name}
                          style={{ width: '100%' }}
                          placeholder="Last name"
                          value={field.value || ''}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                        <div>
                          {getFormErrorMessage(field.name)}
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="flex-auto">
                  <Controller
                    name="position"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div>
                        <InputText
                          id={field.name}
                          style={{ width: '100%' }}
                          placeholder="Position"
                          value={field.value || ''}
                          className={classNames({ 'p-invalid': fieldState.error })}
                          onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                          aria-describedby={`${field.name}-info`}
                        />
                      </div>
                    )}
                  />
                </div>

                {/* <div className="flex-auto">
                <Controller
                  name="department"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div>
                      <InputText
                        id={field.name}
                        placeholder="Department"
                        value={field.value}
                        className={classNames({ 'p-invalid': fieldState.error })}
                        onChange={(e) => { field.onChange(e.target.value); trigger('department'); }}
                        aria-describedby={`${field.name}-info`}
                      />
                    </div>
                  )}
                />
              </div>

              <div className="flex-auto md:w-14rem">
                <Dropdown
                  value={selectedAge}
                  onChange={(e) => setSelectedAge(e.value)}
                  options={age}
                  optionLabel="age"
                  placeholder="Select an age range"
                  className="w-full md:w-30rem"
                />
              </div>

              <div className="flex-auto md:w-14rem">
                <Dropdown
                  value={selectedGender}
                  onChange={(e) => setSelectedGender(e.value)}
                  options={genders}
                  optionLabel="name"
                  placeholder="Select a gender"
                  className="w-full md:w-30rem"
                />
              </div> */}
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-1rem' }}>
                <Button disabled={!isValid} label="Add" type="submit" icon="pi pi-plus-circle" />
              </div>
            </TabPanel>
          </TabView>
        </Fieldset>
      </form>
    </>
  );
}

ManageGroupFormComponent.propTypes = {
  group: PropTypes.object,
  setGroup: PropTypes.func.isRequired,
  setGroupUsers: PropTypes.func.isRequired,
};

ManageGroupFormComponent.defaultProps = {
  group: null,
};

export default ManageGroupFormComponent;
