import axios from 'axios';
import { store } from '../redux/store/store';
import { setUserInfoState } from '../redux/reducers/actions/userInfoActions';

const baseURL = '/auth/';
const axiosHandlerAuth = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
let csrfToken = '';

axiosHandlerAuth.interceptors.request.use(
  (config) => {
    config.headers['X-CSRF-Token'] = csrfToken || store.getState().userInfo.csrf;
    return config;
  },
  (error) => Promise.reject(error),
);

// TODO: Improve handle errors
axiosHandlerAuth.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      store.dispatch(setUserInfoState(
        { ...response?.data?.data, name: response?.data?.data?.username },
      ));
      return Promise.resolve(response.data);
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response?.headers['x-csrf-token']) {
      csrfToken = error.response?.headers['x-csrf-token'];
      store.dispatch(setUserInfoState({ csrf: csrfToken }));
    }
    return Promise.reject(error);
  },
);

export default axiosHandlerAuth;
