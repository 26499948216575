import axios from 'axios';
import { store } from '../redux/store/store'
import { cleanUserInfoState } from '../redux/reducers/actions/userInfoActions';

const baseURL = '/api/';
const axiosHandler = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// TODO: Improve handle errors
axiosHandler.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      return Promise.resolve(response.data);
    }
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(cleanUserInfoState())
      return
    }
    return Promise.resolve(error)
  },
);

export default axiosHandler;
