import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login, getLoggedUser } from '../../apis/authentication.ts';
import { setUserInfoState } from '../../redux/reducers/actions/userInfoActions';
import { useToast } from '../../context/ToastContext';
import getUserInfoState from '../../redux/reducers/selectors/userInfo';

function LoginPage() {
  const logged = useSelector(getUserInfoState);
  if (logged.name !== '') {
    window.location.href = '/';
  }
  const [showPassword, setShowPassword] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const validationSchema = yup.object().shape({
    username: yup.string().min(2, 'Username must be at least 2 characters').max(255, 'Username must be at most 255 characters').required('Username is required'),
    password: yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const {
    handleSubmit, trigger, getValues, control, formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const onSubmit = async () => {
    await getLoggedUser();
    const data = getValues();
    const response = await login(data);
    if (response.success === false) {
      toast.current.show({
        severity: 'error', summary: 'Error', detail: response.message, life: 3000,
      });
    } else {
      dispatch(setUserInfoState(
        { name: response.username, role: response.role, id: response.id },
      ));
      navigate('/');
    }
  };

  const getFormErrorMessage = (name) => (errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>);

  return (
    (!logged || logged.name === '') && (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          {/* <img src="/assets/tenants/repsol.svg" alt="logo" height={50} className="mb-3" /> */}
          <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              name="username"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className="block text-900 font-medium mb-2 text-left" htmlFor={field.name}>Username</label>
                  <InputText
                    id={field.name}
                    value={field.value}
                    placeholder="Username"
                    className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                    onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                    aria-describedby={`${field.name}-info`}
                  />
                  <div>
                    {getFormErrorMessage(field.name)}
                  </div>
                </>
              )}
            />
          </div>

          <div>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <label className="block text-900 font-medium mb-2 text-left mt-3" htmlFor={field.name}>
                    Password
                  </label>
                  <span className="p-input-icon-right w-full">
                    <InputText
                      id={field.name}
                      type={showPassword ? 'text' : 'password'}
                      value={field.value}
                      placeholder="Password"
                      className={`w-full ${fieldState.error ? 'p-invalid' : ''}`}
                      onChange={(e) => { field.onChange(e.target.value); trigger(field.name); }}
                      aria-describedby={`${field.name}-info`}
                    />
                    <i
                      className={showPassword ? 'pi pi-eye-slash' : 'pi pi-eye'}
                      onClick={() => setShowPassword(!showPassword)}
                      tabIndex="0"
                      role="button"
                      aria-label="Toggle New Password Visibility"
                      onKeyDown={(e) => e.key === 'Enter' && setShowPassword(!showPassword)}
                    />
                  </span>

                  <div>
                    {getFormErrorMessage(field.name)}
                  </div>
                </>
              )}
            />
          </div>

          <Button label="Sign In" icon="pi pi-user" className="w-full mt-3" type="submit" disabled={!isValid} />
        </form>
      </div>
    </div>
    )
  );
}

export default LoginPage;
